// Here you can add other styles

.react-datepicker__input-container > input{
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--cui-input-color, rgba(44, 56, 74, 0.95));
  background-color: var(--cui-input-bg, #fff);
  background-clip: padding-box;
  border: 1px solid var(--cui-input-border-color, #b1b7c1);
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.rt-thead.-header {
  background-color: grey !important;
  color: white !important;
}

.rt-resizable-header {
  text-align: left !important;
}

.ReactTable .rt-th {
  white-space: unset !important;
  word-wrap: break-word !important;
}

.disabled {
  opacity: 0.2 !important;
}
